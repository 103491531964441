import styled from 'styled-components'
import Img from 'gatsby-image'
import { Row, Col } from 'react-styled-flexboxgrid'
import variables from '../variables'
import { TextWrapper } from '../base'
import { Heading } from '../../components'
import { headerStyles } from '../global'

export const Container = styled.div`
  width: 100%;
`

export const SectionContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
  width: 100%;
  max-width: 55em;
  margin: 50px auto 0 auto;
  
  p {
    max-width: 55em;
  }
`
export const SectionRow = styled(Row)`
  width: 100%;
  margin-bottom: 25px;
`

export const SectionCol = styled(Col)`
  display: flex;
  justify-content: center;
`

export const SectionHeader = styled(Heading)`
  ${headerStyles.headerSmall};
`

export const SectionText = styled.p`
  text-align: justify;
  text-justify: inter-word; 
`

export const ChallengeHeader = styled.div`
  display: flex;
  align-items: center;
`

export const ChallengeHeaderImg = styled.img`
  margin-right: 15px;
`

export const JunctionImg = styled(Img)`
  width: 100%;
  margin: 30px 0;
`

export const ListContainer = styled(TextWrapper)`
  padding-top: 0 !important;
  max-width: 65em;
`
