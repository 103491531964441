import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import {
  Section,
  PageHeader,
  SimpleCallToActionBanner,
  Markdown,
  CollapsibleList
} from '../components'
import * as styles from '../styles/pages/junction-2020'
import variables from '../styles/variables'
import noodles from '../../assets/noodles.svg'
import friendship from '../../assets/friendship.svg'
import prize from '../../assets/prize.svg'
import junctionLogo from '../../assets/junction.svg'
import aitoConsoleBlack from '../../assets/aito-console-black.svg'
import dataSearching from '../../assets/data-searching.svg'
import devBlack from '../../assets/dev-black.svg'
import rpaDeveloper from '../../assets/rpa-developers-black.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function JunctionPage({ data }) {
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)
  const challengeImages = [noodles, friendship, prize]
  const proceedImages = [
    junctionLogo, aitoConsoleBlack, dataSearching, devBlack, rpaDeveloper
  ]
  const firstChild = (
    <PageHeader
      header={node.header}
      info={node.headerInfo}
    />
  )
  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <styles.SectionContainer>
            <styles.SectionRow>
              <styles.SectionHeader>
                {node.aboutJunctionHeader}
              </styles.SectionHeader>
              <Markdown>
                {node.aboutJunctionText}
              </Markdown>
              <styles.JunctionImg fluid={node.aboutJunctionImg.childImageSharp.fluid} />
            </styles.SectionRow>
            {_.map(node.challengeInfo, (info, i) => {
              return (
                <styles.SectionRow key={i}>
                  <styles.ChallengeHeader>
                    <styles.ChallengeHeaderImg src={challengeImages[i]} alt="" />
                    <styles.SectionHeader>
                      {info.header}
                    </styles.SectionHeader>
                  </styles.ChallengeHeader>
                  <Markdown>
                    {info.text}
                  </Markdown>
                </styles.SectionRow>
              )
            })}
          </styles.SectionContainer>
          <styles.ListContainer>
            <CollapsibleList
              reverse
              header={node.proceedHeader}
              items={node.proceedSteps}
              images={proceedImages}
              imageWidth="350px"
              imageHeight="150px"
            />
          </styles.ListContainer>
          <SimpleCallToActionBanner />
        </styles.Container>
      </Section>
    </PageLayout>
  )
}

JunctionPage.propTypes = propTypes

export const contentQuery = graphql`
query GetJunctionContent {
  allYaml(filter: {file: {eq: "junction"}}) {
    edges {
      node {
        title
        header
        headerInfo
        metaDescription

        aboutJunctionHeader
        aboutJunctionText
        aboutJunctionImg {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        challengeInfo {
          header
          text
        }

        proceedHeader
        proceedSteps {
          header
          text
          button {
            text
            url
            disabled
          }
        }
      }
    }
  }
}
`
